<!--  -->
<template>
  <div id="Exam">
    <VueDragResize
      v-if="quanpingshow"
      v-on:resizing="resize"
      v-on:dragging="resize"
      :isActive="true"
      :w="800"
      :h="500"
    >
      <el-dialog
        class="question"
        title="按住可随意拖动"
        :visible.sync="quanpingshow"
        :close-on-click-modal="false"
        :modal="false"
        :modal-append-to-body="false"
        :fullscreen="false"
        :style="{ top: top + 'px', left: left + 'px' }"
        :before-close="handleClose"
      >
        <div v-html="gridData"></div>
      </el-dialog>
    </VueDragResize>
    <el-dialog
      class="tijiaoDialog"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <span>你还有试题没做完</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">继 续</el-button>
        <el-button type="primary" @click="clicksubmit">交 卷</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="tijiaoDialog"
      :visible.sync="DialogVisible"
      width="30%"
      center
    >
      <span>恭喜你！试题已完成</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="clicksubmit">交 卷</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="tijiaoDialog tijiaoDialog1"
      :visible.sync="DialogVisible1"
      width="30%"
      center
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div>
        <van-count-down @finish="finish" :time="timesubmit" format="ss" />
        <div>S后自动交卷</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="clicksubmit">交 卷</el-button>
      </span>
    </el-dialog>
    <div class="Exam_right">
      <div class="Exam_left_top">
        <p>{{ name }}</p>
        <div>
          <p>分数：{{ gradeall }}</p>
          <div>
            <p>难度</p>

            <el-rate v-model="difficultys" :colors="colors" disabled :size="40">
            </el-rate>
          </div>
        </div>
      </div>
      <div class="Exam_right_top">
        <div>
          <img src="@/assets/image/dati.png" alt="" /><span>答题进度</span>
        </div>
        <div>
          <div class="speed">
            <div
              :style="{ width: (number / allListData.length) * 100 + '%' }"
            ></div>
          </div>
          <div class="num">{{ number }}/{{ allListData.length }}</div>
        </div>

        <div class="time">
          <div class="countDown">
            <van-count-down
              @change="changtime()"
              ref="countDown"
              :time="time"
            />
          </div>
          <div class="timeDown">
            <div v-if="timeZanting" class="Pause" @click="clickPause"></div>
            <div></div>
            <!-- <div v-else class="zanting" @click="clickzanting"></div> -->

            <div class="caocun" v-if="false"></div>
          </div>
        </div>
        <el-button type="danger" @click="jiaojuan">交卷</el-button>
      </div>
      <el-dialog
        class="pauseBool"
        :visible.sync="pauseBool"
        width="330px"
        :show-close="false"
        top="260px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        center
      >
        <div>休息一下，马上回来！</div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="clickzanting">继续做题</el-button>
        </span>
      </el-dialog>
      <div class="Exam_right_main">
        <div class="Exam_right_main_title">记录板</div>
        <!-- <ul>
          <li class="text_done">
            <i>
              <img src="../../assets/image/Icon-success.png" alt="" />
            </i>
            <div class="text_done_right">
              <p>已完成</p>
              <p>
                <span>{{ number }}</span
                >道题
              </p>
            </div>
          </li>
          <li class="text_tag">
            <i>
              <img src="../../assets/image/Icon-info.png" alt="" />
            </i>
            <div class="text_done_right">
              <p>做标记</p>
              <p>
                <span>{{ biaojiNum }}</span
                >道题
              </p>
            </div>
          </li>
          <li class="text_undone">
            <i>
              <img src="../../assets/image/Icon-warning.png" alt="" />
            </i>
            <div class="text_done_right">
              <p>未完成</p>
              <p>
                <span>{{ allListData.length - number }}</span
                >道题
              </p>
            </div>
          </li>
        </ul> -->
        <ul class="index__answerHint clearfix">
          <li><i class="index__yizuo"></i>已做</li>
          <li><i class="index__weizuo"></i>未做</li>
          <li v-if="false">
            <i class="index__biaoji"></i>
            <i class="index__biaojixi"></i>标记
          </li>
        </ul>
        <div class="index__BigtiNumberBox">
          <div class="stem">【题目】</div>
          <ul>
            <li
              @click="clickCard(index)"
              v-for="(item, index) in allListData"
              :key="index"
              :class="[
                { active: item.makeQuestion },
                { cur: item.biaoji == 1 },
                { act: item.topicBool },
              ]"
            >
              {{ index + 1 }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="Exam_left">
      <div class="Exam_left_main">
        <div class="allList" v-for="(item, index) in allListData" :key="index">
          <div v-show="item.topicBool">
            <!-- 多元填空 -->
            <div
              class="blankChoice"
              v-if="item.question.type == 6 ? true : false"
            >
              <div class="allListTitle">
                <div class="allListLeftborder"></div>
                <div class="fontweight">
                  <div>
                    <span>{{ index + 1 }}</span>
                  </div>
                  <div>
                    <span v-if="item.question.defined == '' ? false : true"
                      >({{ item.question.defined }})</span
                    ><span v-else>{{
                      item.question.type == 6 ? "多元填空" : ""
                    }}</span>
                  </div>
                  <div>({{ item.question.grade }}分)</div>
                </div>
                <div class="connction">
                  <div
                    v-if="item.question.collection == 1"
                    @click="clickActive(index)"
                  >
                    <van-icon size="1.5em" color="#ffb41c" name="star" />
                    <span>收藏</span>
                  </div>
                  <div v-else @click="clickActive(index)">
                    <van-icon color="#ccc" name="star" size="1.5em" />
                    <span>收藏</span>
                  </div>

                  <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                </div>
              </div>
              <div class="singleScreen">
                <!-- 文字 -->
                <div
                  class="blankWords"
                  v-if="item.question.stem_type == 1 ? true : false"
                >
                  <div class="stemBox">
                    <div
                      @input="clickInput($event, index)"
                      class="blankStem"
                      :class="'blankStem' + index"
                      v-html="item.question.stem"
                    ></div>
                  </div>
                </div>
                <!-- 音频 -->
                <div
                  class="blankAudio"
                  v-if="item.question.stem_type == 2 ? true : false"
                >
                  <div class="stemBox">
                    <div class="audioBox">
                      <div class="btnback">
                        <div class="back" @click="clickSnd(-15)">
                          <img src="../../assets/image/kuaijin1.png" alt="" />
                        </div>
                        <div>{{ item.question.file.filename }}</div>
                        <div class="forward" @click="clickSnd(15)">
                          <img src="../../assets/image/kuaijin2.png" alt="" />
                        </div>
                      </div>
                      <audio controls controlslist="nodownload">
                        <source
                          :src="item.question.file.playpath"
                          type="audio/mp3"
                        />
                        您的浏览器不支持 HTML5 audio 标签。
                      </audio>
                    </div>
                    <div
                      @input="clickInput($event, index)"
                      class="blankStem"
                      :class="'blankStem' + index"
                      v-html="item.question.stem"
                    ></div>
                  </div>
                </div>
                <!-- 图片 -->
                <div
                  class="blankWords"
                  v-if="item.question.stem_type == 4 ? true : false"
                >
                  <div class="stemBox">
                    <img
                      @click="clickShowImagePreview(index)"
                      :src="item.question.file.playpath"
                      v-if="item.question.file"
                      alt=""
                    />
                    <div
                      @input="clickInput($event, index)"
                      class="blankStem"
                      :class="'blankStem' + index"
                      v-html="item.question.stem"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="item.topicBool">
            <!-- 单选 -->
            <div
              class="singleChoice"
              v-if="item.question.type == 1 ? true : false"
            >
              <div class="allListTitle">
                <div class="allListLeftborder"></div>
                <div class="fontweight">
                  <div>
                    <span>{{ index + 1 }}</span>
                  </div>
                  <div>
                    <span v-if="item.question.defined == '' ? false : true"
                      >({{ item.question.defined }})</span
                    ><span v-else>{{
                      item.question.type == 1 ? "单选题" : ""
                    }}</span>
                  </div>
                  <div>({{ item.question.grade }}分)</div>
                </div>
                <div class="connction">
                  <div
                    v-if="item.question.collection == 1"
                    @click="clickActive(index)"
                  >
                    <van-icon size="1.5em" color="#ffb41c" name="star" />
                    <span>收藏</span>
                  </div>
                  <div v-else @click="clickActive(index)">
                    <van-icon color="#ccc" name="star" size="1.5em" />
                    <span>收藏</span>
                  </div>

                  <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                </div>
              </div>
              <div class="singleScreen">
                <!-- 文字 -->
                <div
                  class="singleWords"
                  v-if="item.question.stem_type == 1 ? true : false"
                >
                  <div class="stemBox">
                    <div class="singleStem" v-html="item.question.stem"></div>
                    <div
                      v-for="(item1, index1) in item.question.single_choices"
                      :key="index1"
                      class="singleSelectList"
                      @click="clickSingle(index, index1)"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''">
                        {{ listSum[index1] }}
                      </div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
                <!-- 音频 -->
                <div
                  class="singleAudio"
                  v-if="item.question.stem_type == 2 ? true : false"
                >
                  <div class="stemBox">
                    <div class="audioBox">
                      <div class="btnback">
                        <div class="back" @click="clickSnd(-15)">
                          <img src="../../assets/image/kuaijin1.png" alt="" />
                        </div>
                        <div>{{ item.question.file.filename }}</div>
                        <div class="forward" @click="clickSnd(15)">
                          <img src="../../assets/image/kuaijin2.png" alt="" />
                        </div>
                      </div>
                      <audio controls controlslist="nodownload">
                        <source
                          :src="item.question.file.playpath"
                          type="audio/mp3"
                        />
                        您的浏览器不支持 HTML5 audio 标签。
                      </audio>
                    </div>

                    <div class="singleStem" v-html="item.question.stem"></div>

                    <div
                      v-for="(item1, index1) in item.question.single_choices"
                      :key="index1"
                      class="singleSelectList"
                      @click="clickSingle(index, index1)"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''">
                        {{ listSum[index1] }}
                      </div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
                <!-- 视频 -->
                <div
                  class="singleVideo"
                  v-if="item.question.stem_type == 3 ? true : false"
                >
                  <div class="stemBox">
                    <video
                      :src="item.question.file.playpath"
                      controls="controls"
                    ></video>
                    <div class="singleStem" v-html="item.question.stem"></div>

                    <div
                      v-for="(item1, index1) in item.question.single_choices"
                      :key="index1"
                      class="singleSelectList"
                      @click="clickSingle(index, index1)"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''">
                        {{ listSum[index1] }}
                      </div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
                <!-- 图片 -->
                <div
                  class="singleVideo"
                  v-if="item.question.stem_type == 4 ? true : false"
                >
                  <div class="stemBox">
                    <img
                      @click="clickShowImagePreview(index)"
                      :src="item.question.file.playpath"
                      v-if="item.question.file"
                      alt=""
                    />
                    <div class="singleStem" v-html="item.question.stem"></div>

                    <div
                      v-for="(item1, index1) in item.question.single_choices"
                      :key="index1"
                      class="singleSelectList"
                      @click="clickSingle(index, index1)"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''">
                        {{ listSum[index1] }}
                      </div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 多选题 -->
            <div
              class="multipleChoice"
              v-if="item.question.type == 2 ? true : false"
            >
              <div class="allListTitle">
                <div class="allListLeftborder"></div>
                <div class="fontweight">
                  <div>
                    <span>{{ index + 1 }}</span>
                  </div>
                  <div>
                    <span v-if="item.question.defined == '' ? false : true"
                      >({{ item.question.defined }})</span
                    ><span v-else>{{
                      item.question.type == 2 ? "多选题" : ""
                    }}</span>
                  </div>
                  <div>({{ item.question.grade }}分)</div>
                </div>
                <div class="connction">
                  <div
                    v-if="item.question.collection == 1"
                    @click="clickActive(index)"
                  >
                    <van-icon size="1.5em" color="#ffb41c" name="star" />
                    <span>收藏</span>
                  </div>
                  <div v-else @click="clickActive(index)">
                    <van-icon color="#ccc" name="star" size="1.5em" />
                    <span>收藏</span>
                  </div>

                  <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                </div>
              </div>
              <div class="singleScreen">
                <!-- 文字 -->
                <div
                  class="multipleWords"
                  v-if="item.question.stem_type == 1 ? true : false"
                >
                  <div class="stemBox">
                    <div class="multipleStem" v-html="item.question.stem"></div>
                    <div
                      v-for="(item1, index1) in item.question.multiple_choices"
                      :key="index1"
                      class="multipleSelectList"
                      @click="clickMany(index, index1)"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''">
                        {{ listSum[index1] }}
                      </div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
                <!-- 音频 -->
                <div
                  class="multipleAudio"
                  v-if="item.question.stem_type == 2 ? true : false"
                >
                  <div class="stemBox">
                    <div class="audioBox">
                      <div class="btnback">
                        <div class="back" @click="clickSnd(-15)">
                          <img src="../../assets/image/kuaijin1.png" alt="" />
                        </div>
                        <div>{{ item.question.file.filename }}</div>
                        <div class="forward" @click="clickSnd(15)">
                          <img src="../../assets/image/kuaijin2.png" alt="" />
                        </div>
                      </div>
                      <audio controls controlslist="nodownload">
                        <source
                          :src="item.question.file.playpath"
                          type="audio/mp3"
                        />
                        您的浏览器不支持 HTML5 audio 标签。
                      </audio>
                    </div>

                    <div class="multipleStem" v-html="item.question.stem"></div>

                    <div
                      v-for="(item1, index1) in item.question.multiple_choices"
                      :key="index1"
                      class="multipleSelectList"
                      @click="clickMany(index, index1)"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''">
                        {{ listSum[index1] }}
                      </div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
                <!-- 视频 -->
                <div
                  class="multipleVideo"
                  v-if="item.question.stem_type == 3 ? true : false"
                >
                  <div class="stemBox">
                    <video
                      :src="item.question.file.playpath"
                      controls="controls"
                    ></video>
                    <div class="multipleStem" v-html="item.question.stem"></div>

                    <div
                      v-for="(item1, index1) in item.question.multiple_choices"
                      :key="index1"
                      class="multipleSelectList"
                      @click="clickMany(index, index1)"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''">
                        {{ listSum[index1] }}
                      </div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
                <!-- 图片 -->
                <div
                  class="multipleVideo"
                  v-if="item.question.stem_type == 4 ? true : false"
                >
                  <div class="stemBox">
                    <img
                      @click="clickShowImagePreview(index)"
                      :src="item.question.file.playpath"
                      v-if="item.question.file"
                      alt=""
                    />
                    <div class="multipleStem" v-html="item.question.stem"></div>

                    <div
                      v-for="(item1, index1) in item.question.multiple_choices"
                      :key="index1"
                      class="multipleSelectList"
                      @click="clickMany(index, index1)"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''">
                        {{ listSum[index1] }}
                      </div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 判断题 -->
            <div
              class="judgesChoice"
              v-if="item.question.type == 3 ? true : false"
            >
              <div class="allListTitle">
                <div class="allListLeftborder"></div>
                <div class="fontweight">
                  <div>
                    <span>{{ index + 1 }}</span>
                  </div>
                  <div>
                    <span v-if="item.question.defined == '' ? false : true"
                      >({{ item.question.defined }})</span
                    ><span v-else>{{
                      item.question.type == 3 ? "判断题" : ""
                    }}</span>
                  </div>
                  <div>({{ item.question.grade }}分)</div>
                </div>
                <div class="connction">
                  <div
                    v-if="item.question.collection == 1"
                    @click="clickActive(index)"
                  >
                    <van-icon size="1.5em" color="#ffb41c" name="star" />
                    <span>收藏</span>
                  </div>
                  <div v-else @click="clickActive(index)">
                    <van-icon color="#ccc" name="star" size="1.5em" />
                    <span>收藏</span>
                  </div>

                  <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                </div>
              </div>
              <div class="singleScreen">
                <!-- 文字 -->
                <div
                  class="judgesWords"
                  v-if="item.question.stem_type == 1 ? true : false"
                >
                  <div class="stemBox">
                    <div class="judgesStem" v-html="item.question.stem"></div>
                    <div
                      v-for="(item1, index1) in item.question.judges"
                      :key="index1"
                      class="judgesSelectList"
                      @click="clickJudges(index, index1)"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''"></div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
                <!-- 音频 -->
                <div
                  class="judgesAudio"
                  v-if="item.question.stem_type == 2 ? true : false"
                >
                  <div class="stemBox">
                    <div class="audioBox">
                      <div class="btnback">
                        <div class="back" @click="clickSnd(-15)">
                          <img src="../../assets/image/kuaijin1.png" alt="" />
                        </div>
                        <div>{{ item.question.file.filename }}</div>
                        <div class="forward" @click="clickSnd(15)">
                          <img src="../../assets/image/kuaijin2.png" alt="" />
                        </div>
                      </div>
                      <audio controls controlslist="nodownload">
                        <source
                          :src="item.question.file.playpath"
                          type="audio/mp3"
                        />
                        您的浏览器不支持 HTML5 audio 标签。
                      </audio>
                    </div>

                    <div class="judgesStem" v-html="item.question.stem"></div>

                    <div
                      v-for="(item1, index1) in item.question.judges"
                      :key="index1"
                      class="judgesSelectList"
                      @click="clickJudges(index, index1)"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''"></div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
                <!-- 视频 -->
                <div
                  class="judgesVideo"
                  v-if="item.question.stem_type == 3 ? true : false"
                >
                  <div class="stemBox">
                    <video
                      :src="item.question.file.playpath"
                      controls="controls"
                    ></video>
                    <div class="judgesStem" v-html="item.question.stem"></div>

                    <div
                      v-for="(item1, index1) in item.question.judges"
                      :key="index1"
                      class="judgesSelectList"
                      @click="clickJudges(index, index1)"
                    >
                      <div :class="item1.isSelect ? 'isSelect' : ''"></div>
                      <p v-html="item1.answer"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 阅读题 -->
            <div
              class="readingChoice"
              v-if="item.question.type == 9 ? true : false"
            >
              <div class="allListTitle">
                <div class="allListLeftborder"></div>
                <div class="fontweight">
                  <div>
                    <span>{{ index + 1 }}</span>
                  </div>
                  <div>
                    <span v-if="item.question.defined == '' ? false : true"
                      >({{ item.question.defined }})</span
                    ><span v-else>{{
                      item.question.type == 9 ? "阅读题" : ""
                    }}</span>
                  </div>
                  <div>({{ item.question.grade }}分)</div>
                </div>
                <div class="connction">
                  <div
                    v-if="item.question.collection == 1"
                    @click="clickActive(index)"
                  >
                    <van-icon size="1.5em" color="#ffb41c" name="star" />
                    <span>收藏</span>
                  </div>
                  <div v-else @click="clickActive(index)">
                    <van-icon color="#ccc" name="star" size="1.5em" />
                    <span>收藏</span>
                  </div>

                  <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                </div>
                <div class="card">
                  <div class="allListLeftborder"></div>
                  <div class="fontweight">答题卡</div>
                </div>
              </div>
              <div class="showMax" @click="showMax(index)">材料全屏</div>
              <div class="singleScreen">
                <!-- 文字 -->
                <div
                  class="readingWords"
                  v-if="item.question.stem_type == 1 ? true : false"
                >
                  <div class="stemBox">
                    <div class="stem">
                      <div
                        class="readingStem"
                        v-html="item.question.stem"
                      ></div>
                    </div>
                    <div class="border"></div>
                    <div class="selectList">
                      <div
                        class="readTopic"
                        v-for="(item1, index1) in item.question.reads"
                        :key="index1"
                      >
                        <div class="readingStem">
                          {{ index1 + 1 }}.{{
                            item1.question.stem == "null"
                              ? ""
                              : item1.question.stem
                          }}<span>({{ item1.question.grade }}分)</span>
                        </div>

                        <div
                          class="readingSelectList"
                          v-for="(item2, index2) in item1.question
                            .single_choices"
                          :key="index2"
                          @click="clickReading(index, index1, index2)"
                        >
                          <div :class="item2.isSelect ? 'isSelect' : ''">
                            {{ listSum[index2] }}
                          </div>
                          <p v-html="item2.answer"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 音频 -->
                <div
                  class="readingAudio"
                  v-if="item.question.stem_type == 2 ? true : false"
                >
                  <div class="stemBox">
                    <div class="stem">
                      <div class="audioBox">
                        <div class="btnback">
                          <div class="back" @click="clickSnd(-15)">
                            <img src="../../assets/image/kuaijin1.png" alt="" />
                          </div>
                          <div>{{ item.question.file.filename }}</div>
                          <div class="forward" @click="clickSnd(15)">
                            <img src="../../assets/image/kuaijin2.png" alt="" />
                          </div>
                        </div>
                        <audio controls controlslist="nodownload">
                          <source
                            :src="item.question.file.playpath"
                            type="audio/mp3"
                          />
                          您的浏览器不支持 HTML5 audio 标签。
                        </audio>
                      </div>

                      <div
                        class="readingStem"
                        v-html="item.question.stem"
                      ></div>
                    </div>
                    <div class="border"></div>
                    <div class="selectList">
                      <div
                        class="readTopic"
                        v-for="(item1, index1) in item.question.reads"
                        :key="index1"
                      >
                        <div class="readingStem">
                          {{ index1 + 1 }}.{{
                            item1.question.stem == "null"
                              ? ""
                              : item1.question.stem
                          }}<span>({{ item1.question.grade }}分)</span>
                        </div>

                        <div
                          class="readingSelectList"
                          v-for="(item2, index2) in item1.question
                            .single_choices"
                          :key="index2"
                          @click="clickReading(index, index1, index2)"
                        >
                          <div :class="item2.isSelect ? 'isSelect' : ''">
                            {{ listSum[index2] }}
                          </div>
                          <p v-html="item2.answer"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 视频 -->
                <div
                  class="readingVideo"
                  v-if="item.question.stem_type == 3 ? true : false"
                >
                  <div class="stemBox">
                    <div class="stem">
                      <video
                        :src="item.question.file.playpath"
                        controls="controls"
                      ></video>
                      <div
                        class="readingStem"
                        v-html="item.question.stem"
                      ></div>
                    </div>
                    <div class="border"></div>
                    <div class="selectList">
                      <div
                        class="readTopic"
                        v-for="(item1, index1) in item.question.reads"
                        :key="index1"
                      >
                        <div class="readingStem">
                          {{ index1 + 1 }}.{{
                            item1.question.stem == "null"
                              ? ""
                              : item1.question.stem
                          }}<span>({{ item1.question.grade }}分)</span>
                        </div>

                        <div
                          class="readingSelectList"
                          v-for="(item2, index2) in item1.question
                            .single_choices"
                          :key="index2"
                          @click="clickReading(index, index1, index2)"
                        >
                          <div :class="item2.isSelect ? 'isSelect' : ''">
                            {{ listSum[index2] }}
                          </div>
                          <p v-html="item2.answer"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 图片 -->
                <div
                  class="readingVideo"
                  v-if="item.question.stem_type == 4 ? true : false"
                >
                  <div class="stemBox">
                    <div class="stem">
                      <img
                        @click="clickShowImagePreview(index)"
                        :src="item.question.file.playpath"
                        v-if="item.question.file"
                        alt=""
                      />
                      <div
                        class="readingStem"
                        v-html="item.question.stem"
                      ></div>
                    </div>
                    <div class="border"></div>
                    <div class="selectList">
                      <div
                        class="readTopic"
                        v-for="(item1, index1) in item.question.reads"
                        :key="index1"
                      >
                        <div class="readingStem">
                          {{ index1 + 1 }}.{{
                            item1.question.stem == "null"
                              ? ""
                              : item1.question.stem
                          }}<span>({{ item1.question.grade }}分)</span>
                        </div>

                        <div
                          class="readingSelectList"
                          v-for="(item2, index2) in item1.question
                            .single_choices"
                          :key="index2"
                          @click="clickReading(index, index1, index2)"
                        >
                          <div :class="item2.isSelect ? 'isSelect' : ''">
                            {{ listSum[index2] }}
                          </div>
                          <p v-html="item2.answer"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 问答题 -->
            <div
              class="questionsChoice"
              v-if="item.question.type == 4 ? true : false"
            >
              <div class="allListTitle">
                <div class="allListLeftborder"></div>
                <div class="fontweight">
                  <div>
                    <span>{{ index + 1 }}</span>
                  </div>
                  <div>
                    <span v-if="item.question.defined == '' ? false : true"
                      >({{ item.question.defined }})</span
                    ><span v-else>{{
                      item.question.type == 4 ? "问答题" : ""
                    }}</span>
                  </div>
                  <div>({{ item.question.grade }}分)</div>
                </div>
                <div class="connction">
                  <div
                    v-if="item.question.collection == 1"
                    @click="clickActive(index)"
                  >
                    <van-icon size="1.5em" color="#ffb41c" name="star" />
                    <span>收藏</span>
                  </div>
                  <div v-else @click="clickActive(index)">
                    <van-icon color="#ccc" name="star" size="1.5em" />
                    <span>收藏</span>
                  </div>

                  <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                </div>
                <div class="card">
                  <div class="allListLeftborder"></div>
                  <div class="fontweight">答题卡</div>
                </div>
              </div>
              <div class="singleScreen">
                <!-- 文字 -->
                <div
                  class="questionsWords"
                  v-if="item.question.stem_type == 1 ? true : false"
                >
                  <div class="stemBox">
                    <div class="stem">
                      <div
                        class="questionsStem"
                        v-html="item.question.stem"
                      ></div>
                    </div>
                    <div class="border"></div>
                    <div class="selectList">
                      <textarea
                        v-model="item.modelValue"
                        @input="changeText(index)"
                        type="text"
                        placeholder="请输入答案"
                      />
                      <div class="btn">
                        <div></div>
                        <div>
                          <el-button
                            type="primary"
                            :disabled="!item.makeQuestion"
                            @click="clickShowJx(index)"
                            :class="item.isShowJx ? 'activeJx' : ''"
                            >{{ item.message }}</el-button
                          >
                          <el-button
                            :disabled="!item.btnBool"
                            type="success"
                            @click="clickScore(index)"
                            >{{ item.massageScore }}</el-button
                          >
                        </div>
                      </div>
                      <div v-if="item.isShowJx">
                        <div class="wenda">
                          <div class="jiexi">
                            <div>解析:</div>
                            <div v-html="item.question.analysis"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 音频 -->
                <div
                  class="questionsAudio"
                  v-if="item.question.stem_type == 2 ? true : false"
                >
                  <div class="stemBox">
                    <div class="stem">
                      <div class="audioBox">
                        <div class="btnback">
                          <div class="back" @click="clickSnd(-15)">
                            <img src="../../assets/image/kuaijin1.png" alt="" />
                          </div>
                          <div>{{ item.question.file.filename }}</div>
                          <div class="forward" @click="clickSnd(15)">
                            <img src="../../assets/image/kuaijin2.png" alt="" />
                          </div>
                        </div>
                        <audio controls controlslist="nodownload">
                          <source
                            :src="item.question.file.playpath"
                            type="audio/mp3"
                          />
                          您的浏览器不支持 HTML5 audio 标签。
                        </audio>
                      </div>

                      <div
                        class="questionsStem"
                        v-html="item.question.stem"
                      ></div>
                    </div>
                    <div class="border"></div>
                    <div class="selectList">
                      <textarea
                        v-model="item.modelValue"
                        @input="changeText(index)"
                        type="text"
                        placeholder="请输入答案"
                      />
                      <div class="btn">
                        <div></div>
                        <div>
                          <el-button
                            type="primary"
                            :disabled="!item.makeQuestion"
                            @click="clickShowJx(index)"
                            :class="item.isShowJx ? 'activeJx' : ''"
                            >{{ item.message }}</el-button
                          >
                          <el-button
                            :disabled="!item.btnBool"
                            type="success"
                            @click="clickScore(index)"
                            >{{ item.massageScore }}</el-button
                          >
                        </div>
                      </div>
                      <div v-if="item.isShowJx">
                        <div class="wenda">
                          <div class="jiexi">
                            <div>解析:</div>
                            <div v-html="item.question.analysis"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 视频 -->
                <div
                  class="questionsVideo"
                  v-if="item.question.stem_type == 3 ? true : false"
                >
                  <div class="stemBox">
                    <div class="stem">
                      <video
                        :src="item.question.file.playpath"
                        controls="controls"
                      ></video>
                      <div
                        class="questionsStem"
                        v-html="item.question.stem"
                      ></div>
                    </div>
                    <div class="border"></div>
                    <div class="selectList">
                      <textarea
                        v-model="item.modelValue"
                        @change="changeText(index)"
                        type="text"
                        placeholder="请输入答案"
                      />
                      <div class="btn">
                        <div></div>
                        <div>
                          <el-button
                            type="primary"
                            :disabled="!item.makeQuestion"
                            @click="clickShowJx(index)"
                            :class="item.isShowJx ? 'activeJx' : ''"
                            >{{ item.message }}</el-button
                          >
                          <el-button
                            :disabled="!item.btnBool"
                            type="success"
                            @click="clickScore(index)"
                            >{{ item.massageScore }}</el-button
                          >
                        </div>
                      </div>
                      <div v-if="item.isShowJx">
                        <div class="wenda">
                          <div class="jiexi">
                            <div>解析:</div>
                            <div v-html="item.question.analysis"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 图片 -->
                <div
                  class="questionsVideo"
                  v-if="item.question.stem_type == 4 ? true : false"
                >
                  <div class="stemBox">
                    <div class="stem">
                      <img
                        @click="clickShowImagePreview(index)"
                        :src="item.question.file.playpath"
                        v-if="item.question.file"
                        alt=""
                      />
                      <div
                        class="questionsStem"
                        v-html="item.question.stem"
                      ></div>
                    </div>
                    <div class="border"></div>
                    <div class="selectList">
                      <textarea
                        v-model="item.modelValue"
                        @input="changeText(index)"
                        type="text"
                        placeholder="请输入答案"
                      />
                      <div class="btn">
                        <div></div>
                        <div>
                          <el-button
                            type="primary"
                            :disabled="!item.makeQuestion"
                            @click="clickShowJx(index)"
                            :class="item.isShowJx ? 'activeJx' : ''"
                            >{{ item.message }}</el-button
                          >
                          <el-button
                            :disabled="!item.btnBool"
                            type="success"
                            @click="clickScore(index)"
                            >{{ item.massageScore }}</el-button
                          >
                        </div>
                      </div>
                      <div v-if="item.isShowJx">
                        <div class="wenda">
                          <div class="jiexi">
                            <div>解析:</div>
                            <div v-html="item.question.analysis"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <el-dialog
                  title="请判分"
                  :visible.sync="item.dialogVisible"
                  width="30%"
                >
                  <!-- <el-form :model="item" label-width="80px">
                  <el-form-item>
                    <el-input v-model="item.modelScore"></el-input>
                  </el-form-item>
                </el-form> -->
                  <el-input
                    v-model="item.modelScore"
                    placeholder="请填写分数，不填写默认为0"
                    @input="inputModelScore(index)"
                  ></el-input>
                  <div style="color: #e03530">备注：不能超过最高分</div>
                  <span slot="footer" class="dialog-footer">
                    <el-button type="success" @click="clickShowJudgeDown"
                      >自主判分</el-button
                    >
                    <el-button @click="item.dialogVisible = false"
                      >不需要</el-button
                    >
                  </span>
                </el-dialog>
              </div>
            </div>
            <!-- 听力题 -->
            <div
              class="listeningChoice"
              v-if="item.question.type == 10 ? true : false"
            >
              <div class="allListTitle">
                <div class="allListLeftborder"></div>
                <div class="fontweight">
                  <div>
                    <span>{{ index + 1 }}</span>
                  </div>
                  <div>
                    <span v-if="item.question.defined == '' ? false : true"
                      >({{ item.question.defined }})</span
                    ><span v-else>{{
                      item.question.type == 10 ? "听力题" : ""
                    }}</span>
                  </div>
                  <div>({{ item.question.grade }}分)</div>
                </div>
                <!-- 暂时隐藏 -->
                <div class="connction">
                  <div
                    v-if="item.question.collection == 1"
                    @click="clickActive(index)"
                  >
                    <van-icon size="1.5em" color="#ffb41c" name="star" />
                    <span>收藏</span>
                  </div>
                  <div v-else @click="clickActive(index)">
                    <van-icon color="#ccc" name="star" size="1.5em" />
                    <span>收藏</span>
                  </div>

                  <!-- <img
                      @click="sybelBtn(index)"
                      :src="item.biaoji == 0 ? item.sybelImg : item.sybelPath"
                      alt=""
                    />
                    <p>标记</p> -->
                </div>
                <div class="card">
                  <div class="allListLeftborder"></div>
                  <div class="fontweight">答题卡</div>
                </div>
              </div>
              <div class="singleScreen">
                <!-- 音频 -->
                <div
                  class="listeningAudio"
                  v-if="item.question.stem_type == 2 ? true : false"
                >
                  <div class="stemBox">
                    <div class="stem">
                      <div
                        class="audioBox"
                        v-if="item.question_class_id != 275"
                      >
                        <div class="btnback">
                          <div class="back" @click="clickSnd(-15)">
                            <img src="../../assets/image/kuaijin1.png" alt="" />
                          </div>
                          <div>{{ item.question.file.filename }}</div>
                          <div class="forward" @click="clickSnd(15)">
                            <img src="../../assets/image/kuaijin2.png" alt="" />
                          </div>
                        </div>
                        <audio controls controlslist="nodownload">
                          <source
                            :src="item.question.file.playpath"
                            type="audio/mp3"
                          />
                          您的浏览器不支持 HTML5 audio 标签。
                        </audio>
                      </div>
                      <div class="newAudio audioBox" v-else>
                        <audio
                          controls
                          controlslist="nodownload"
                          v-show="false"
                          :ref="'audio' + index"
                          @timeupdate="updateTime"
                          @ended="ended"
                        >
                          <source
                            :src="item.question.file.playpath"
                            type="audio/mp3"
                          />
                        </audio>
                        <div>{{ item.question.file.filename }}</div>
                        <div class="bottm">
                          <i class="el-icon-video-pause" v-if="item.pause"></i>
                          <i
                            class="el-icon-video-play"
                            @click="clickAudio(index)"
                            v-else
                          ></i>
                          <div class="bottomprogress">
                            <div>{{ formatTime(item.time || 0) }}</div>
                            <div class="progress">
                              <div
                                :style="{ width: item.progress + '%' }"
                              ></div>
                            </div>
                            <div>{{ item.question.file.duration }}</div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="listeningStem"
                        v-html="item.question.stem"
                      ></div>
                    </div>
                    <div class="border"></div>
                    <div class="selectList">
                      <textarea
                        v-model="item.modelValue"
                        @input="changeText(index)"
                        type="text"
                        placeholder="请输入答案"
                      />
                      <div class="btn">
                        <div></div>
                        <div>
                          <el-button
                            type="primary"
                            :disabled="!item.makeQuestion"
                            @click="clickShowJx(index)"
                            :class="item.isShowJx ? 'activeJx' : ''"
                            >{{ item.message }}</el-button
                          >
                          <el-button
                            :disabled="!item.btnBool"
                            type="success"
                            @click="clickScore(index)"
                            >{{ item.massageScore }}</el-button
                          >
                        </div>
                      </div>
                      <div v-if="item.isShowJx">
                        <div class="wenda">
                          <div class="jiexi">
                            <div>解析:</div>
                            <div v-html="item.question.analysis"></div>
                          </div>
                        </div>
                      </div>
                      <el-dialog
                        title="请判分"
                        :visible.sync="item.dialogVisible"
                        width="30%"
                      >
                        <!-- <el-form :model="item" label-width="80px">
                  <el-form-item>
                    <el-input v-model="item.modelScore"></el-input>
                  </el-form-item>
                </el-form> -->
                        <el-input
                          v-model="item.modelScore"
                          placeholder="请填写分数，不填写默认为0"
                          @input="inputModelScore(index)"
                        ></el-input>
                        <div class="beizhu" style="color: #e03530">
                          备注：不能超过最高分
                        </div>
                        <span slot="footer" class="dialog-footer">
                          <el-button type="success" @click="clickShowJudgeDown"
                            >自主判分</el-button
                          >
                          <el-button @click="item.dialogVisible = false"
                            >不需要</el-button
                          >
                        </span>
                      </el-dialog>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="Exam_left_bottom">
        <div class="button">
          <div>
            <el-button
              type="primary"
              :disabled="!upperBool"
              @click="clickShow(-1)"
            >
              <span><img src="@/assets/image/iconleft.png" alt="" /></span
              >上一题</el-button
            >
          </div>
          <div>
            <el-button>正做题</el-button>
          </div>

          <div>
            <el-button
              type="primary"
              :disabled="!downBool"
              @click="clickShow(1)"
              >下一题<span
                ><img src="@/assets/image/iconright.png" alt="" /></span
            ></el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 全屏框1 -->
    <el-dialog
      v-if="isChrome"
      class="fullScreenBox1"
      :visible.sync="fullScreenBool"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="760px"
      center
    >
      <div><img src="@/assets/image/fullscreen.png" alt="" /></div>
      <div>
        当前考试<span>已开启防切屏</span>，您即将进入<span>全屏考试</span>
      </div>
      <div>（退出全屏将判定为切屏一次，请勿退出全屏）</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="clickFullScreen"
          >进入全屏开始考试</el-button
        >
      </span>
    </el-dialog>
    <!-- 全屏2 -->
    <el-dialog
      class="fullScreenBox2"
      :visible.sync="fullScreenBoolNum"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="300px"
      center
    >
      <div><img src="@/assets/image/switch.png" alt="" /></div>
      <div>你已离开考试{{ fullNum }}次</div>
      <div>当离开超过2次时将强制交卷</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="clickFullScreenBoolNum"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 全屏3 -->
    <el-dialog
      class="fullScreenBox2 fullScreenBox3"
      :visible.sync="fullScreenBoolNum1"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="300px"
      center
    >
      <div><img src="@/assets/image/switch.png" alt="" /></div>
      <div>你已离开考试{{ fullNum }}次</div>
      <div>当离开超过2次时将强制交卷</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary">交卷中({{ timefullNum }}s)</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import VueDragResize from "vue-drag-resize";
import $ from "jquery";
import { ImagePreview } from "vant";
import screenfull from "screenfull";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    VueDragResize,
  },
  head() {
    return {
      title: this.titlename,
    };
  },
  data() {
    //这里存放数据
    return {
      isChrome: false, //是否是谷歌浏览器
      fullNum: 0, //退出全屏次数
      fullScreenBoolNum: false, //全屏数字框
      fullScreenBoolNum1: false,
      timefullNum: 3,
      fullScreenBool: true, //全屏框
      timeZanting: true,
      name: "", //试卷名
      gradeall: "", //试卷分数
      time: 0, //倒计时
      question: {}, //创建的试卷
      allObj: {}, //所有数据
      allListData: [], //题型列表
      redList: [], //阅读小题
      listSum: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
      defaultIndex: 0,
      upperBool: false, //上一题
      downBool: true, //下一题
      number: 0, //已做题目数量
      centerDialogVisible: false, //没完成交卷弹框
      DialogVisible: false, //试题完成弹框
      DialogVisible1: false, //倒计时结束
      numbertest: 0, //总数
      timeNumber: 0,
      icon: 2,
      Imgitems: [],
      biaojiNum: 0,
      pauseBool: false,
      difficultys: null,
      colors: ["#F7BA2A", "#F7BA2A", "#FF9900"],
      gridData: "",
      quanpingshow: false,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      titlename: "",
      timesubmit: 10 * 1000,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    allListData: {
      handler() {
        let num = 0;
        this.allListData.forEach((v) => {
          if (v.makeQuestion) {
            num += 1;
          }
        });
        this.number = num;
        this.$nextTick(() => {
          $(`.blankStem${this.defaultIndex} input`).each((index, item) => {
            if (this.allListData[this.defaultIndex].question.type == 6) {
              $(item).val(this.allListData[this.defaultIndex].input[index]);
            }
          });
        });
        if (this.defaultIndex == this.allListData.length - 1) {
          this.downBool = false;
        }
      },
      deep: true,
    },
    fullNum() {
      //次数
      if (this.fullNum == 3) {
        this.fullScreenBoolNum = false;
        this.fullScreenBoolNum1 = true;

        let timer = setInterval(() => {
          this.timefullNum--;
          if (this.timefullNum == 0) {
            clearInterval(timer);
            this.clicksubmit();
          }
        }, 1000);
      }
    },
  },
  //方法集合
  methods: {
    formatTime(t) {
      let m = parseInt((t % 3600) / 60);
      m = m < 10 ? "0" + m : m;
      let s = parseInt(t % 60);
      s = s < 10 ? "0" + s : s;
      return m + ":" + s;
    },
    time_to_sec(time) {
      let str = time;
      let arr = str.split(":");
      let ms = parseInt(arr[0] * 60);
      let ss = parseInt(arr[1]);
      let seconds = ms + ss;
      return seconds;
    },
    clickAudio(index) {
      this.$refs["audio" + index][0].play();
      // this.$refs["audio" + index][0].currentTime = 6000;
      this.$set(this.allListData[index], "pause", true);
    },
    updateTime(e) {
      let num = this.allListData.findIndex((v) => v.topicBool);
      let time = e.target.currentTime;
      let totalTime = this.allListData[num].question.file.duration;
      if (time > this.time_to_sec(totalTime)) {
        this.$set(this.allListData[num], "time", this.time_to_sec(totalTime));
      } else {
        this.$set(this.allListData[num], "time", time);
      }
      this.allListData[num].progress =
        (time / this.time_to_sec(totalTime)).toFixed(3) * 100;
    },
    ended() {
      let num = this.allListData.findIndex((v) => v.topicBool);
      this.$set(this.allListData[num], "pause", false);
    },
    clickFullScreenBoolNum() {
      // 全屏
      let _this = this;
      _this.fullScreenBoolNum = false;
      if (!screenfull.isEnabled) {
        this.$message({
          message: "不支持全屏",
          type: "warning",
        });
        return false;
      }
      screenfull.toggle();
    },
    // 全屏
    clickFullScreen() {
      let _this = this;
      _this.fullScreenBool = false;
      if (!screenfull.isEnabled) {
        this.$message({
          message: "不支持全屏",
          type: "warning",
        });
        return false;
      }
      screenfull.toggle();
    },
    //全屏判断状态 取消
    checkFull() {
      //判断浏览器是否处于全屏状态 （需要考虑兼容问题）
      //火狐浏览器
      var isFull =
        document.mozFullScreen ||
        document.fullScreen ||
        //谷歌浏览器及Webkit内核浏览器
        document.webkitIsFullScreen ||
        document.webkitRequestFullScreen ||
        document.mozRequestFullScreen ||
        document.msFullscreenEnabled;
      if (isFull === undefined) {
        isFull = false;
      }
      return isFull;
    },

    clickShowImagePreview(index) {
      // 预览
      let _this = this;
      ImagePreview({
        images: [_this.allListData[index].question.file.playpath],
        closeable: true,
      });
    },
    finish() {
      // 倒计时结束提交
      this.clicksubmit();
    },
    clickSnd(time) {
      var audio = document.getElementsByTagName("audio");
      audio[0].currentTime += time;
      audio[0].play();
    },
    handleClose() {
      this.top = 0;
      this.left = 0;
      this.quanpingshow = false;
    },
    resize(newRect) {
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;
    },
    clickShowJudgeDown() {
      // 自主判分
      let _this = this;

      if (_this.allListData[_this.testIndex].modelScore == "") {
        _this.allListData[_this.testIndex].massageScore = 0 + "分";
      } else {
        _this.allListData[_this.testIndex].massageScore =
          _this.allListData[_this.testIndex].modelScore + "分";
      }

      _this.allListData[_this.testIndex].dialogVisible = false;
      _this.allListData[_this.testIndex].btnBool = false;
    },
    inputModelScore(i) {
      // input最大不能超过当前最高分数
      let _this = this;
      if (
        _this.allListData[i].modelScore > _this.allListData[i].question.grade
      ) {
        _this.allListData[i].modelScore = _this.allListData[i].question.grade;
      } else {
        let str = _this.allListData[i].modelScore;
        var len1 = str.substr(0, 1);
        var len2 = str.substr(1, 1);
        //如果第一位是0，第二位不是点，就用数字把点替换掉
        if (str.length > 1 && len1 == 0 && len2 != ".") {
          str = str.substr(1, 1);
        }
        //第一位不能是.
        if (len1 == ".") {
          str = "";
        }
        //限制只能输入一个小数点
        if (str.indexOf(".") != -1) {
          var str_ = str.substr(str.indexOf(".") + 1);
          if (str_.indexOf(".") != -1) {
            str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
          }
        }
        //正则替换，保留数字和小数点
        str = str.match(/^\d*(\.?\d{0,2})/g)[0] || null;
        _this.$delete(_this.allListData[i], "modelScore");
        _this.$set(_this.allListData[i], "modelScore", str);
      }

      // var reg = /^\d+$|^\d*\.\d+$/g; //只能输入数字和一个小数点的正则表达式
      // if (
      //   _this.allListData[i].modelScore != 0 &&
      //   !reg.test(_this.allListData[i].modelScore)
      // ) {
      //   _this.$delete(_this.allListData[i], "modelScore");
      //   _this.$set(_this.allListData[i], "modelScore", 0);
      // }
    },
    clickScore(i) {
      // 自主判分
      let _this = this;
      _this.testIndex = i;
      _this.$delete(_this.allListData[i], "dialogVisible");
      _this.$set(_this.allListData[i], "dialogVisible", true);
      // this.allListData[i].dialogVisible=true;
    },
    clickShowJx(i) {
      //解析
      let _this = this;
      if (!_this.allListData[i].isShowJx) {
        this.$confirm("请确定您已完成作答，对照解析后自主判分", "查看解析", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            _this.$delete(_this.allListData[i], "isShowJx");
            _this.$set(_this.allListData[i], "isShowJx", true);
            _this.$set(_this.allListData[i], "message", "收起");
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消查看",
            });
          });
      } else {
        _this.$delete(_this.allListData[i], "isShowJx");
        _this.$set(_this.allListData[i], "isShowJx", false);
        _this.$set(_this.allListData[i], "message", "查看解析");
      }
    },
    clickActive(i) {
      // return
      if (this.allListData[i].question.collection == 0) {
        //收藏他
        this.allListData[i].question.collection = 1;
        let obj = {
          collection: 1 /** 收藏动作，1代表收藏，0代表取消收藏 */,
          question_id:
            this.allListData[i]
              .question_id /** 试题id，     如果不传question_id，代表取消该用户所有的收藏试题 */,
        };

        this.$api.collotion(obj).then((res) => {
          this.$message({
            message: "收藏成功",
            type: "success",
          });
        });
      } else {
        //  取消收藏
        this.allListData[i].question.collection = 0;
        let obj = {
          collection: 0 /** 收藏动作，1代表收藏，0代表取消收藏 */,
          question_id:
            this.allListData[i]
              .question_id /** 试题id，     如果不传question_id，代表取消该用户所有的收藏试题 */,
        };
        this.$api.collotion(obj).then((res) => {
          this.$message({
            message: "取消收藏",
            type: "success",
          });
        });
      }
    },
    // 标记
    sybelBtn(i) {
      if (this.allListData[i].biaoji == 0) {
        this.allListData[i].biaoji = 1;
        this.biaojiNum += 1;
      } else {
        this.allListData[i].biaoji = 0;
        this.biaojiNum -= 1;
      }
    },
    changtime(i) {
      if (this.timeNumber > 0) {
        this.timeNumber -= 1;
        if (this.timeNumber == 0) {
          this.DialogVisible1 = true;
          return false;
        }
      }
    },
    changeText(i) {
      // 问答题，听力题以做
      let _this = this;
      if (_this.allListData[i].modelValue != "") {
        _this.$delete(_this.allListData[i], "makeQuestion");
        _this.$set(_this.allListData[i], "makeQuestion", true); //是否做题--答题卡
      }
    },
    clickPause() {
      // 倒计时暂停
      this.$refs.countDown.pause();
      this.pauseBool = true;
    },
    clickzanting() {
      this.$refs.countDown.start();
      this.pauseBool = false;
    },
    clickSingle(index, index1) {
      // 单选选中
      let _this = this;
      _this.allListData[index].makeQuestion = true; //是否做题--答题卡
      _this.$delete(
        _this.allListData[index].question.single_choices[index1],
        "isSelect"
      ); //解决视图不跟新
      // 全都选中-视图实时更新
      _this.$set(
        _this.allListData[index].question.single_choices[index1],
        "isSelect",
        true
      );
      //选中一个
      _this.allListData[index].question.single_choices.forEach((item, inde) => {
        if (inde == index1) {
          item.isSelect = true;
        } else {
          item.isSelect = false;
        }
      });
    },
    clickMany(index, index1) {
      // 多选题
      let _this = this;
      _this.allListData[index].makeQuestion = true; //是否做题--答题卡
      if (
        !_this.allListData[index].question.multiple_choices[index1].isSelect
      ) {
        _this.$delete(
          _this.allListData[index].question.multiple_choices[index1],
          "isSelect"
        ); //解决视图不跟新
        _this.$set(
          _this.allListData[index].question.multiple_choices[index1],
          "isSelect",
          true
        );
      } else {
        _this.$delete(
          _this.allListData[index].question.multiple_choices[index1],
          "isSelect"
        ); //解决视图不跟新
        _this.$set(
          _this.allListData[index].question.multiple_choices[index1],
          "isSelect",
          false
        );
      }
    },
    clickJudges(index, index1) {
      // 判断
      let _this = this;
      _this.allListData[index].makeQuestion = true; //是否做题--答题卡
      _this.$delete(
        _this.allListData[index].question.judges[index1],
        "isSelect"
      ); //解决视图不跟新
      _this.$set(
        _this.allListData[index].question.judges[index1],
        "isSelect",
        true
      );

      _this.allListData[index].question.judges.forEach((v, inde) => {
        if (inde == index1) {
          v.isSelect = true;
        } else {
          v.isSelect = false;
        }
      });
    },
    clickReading(index, index1, index2) {
      // 阅读题
      let _this = this;
      _this.allListData[index].makeQuestion = true; //是否做题--答题卡
      let list = _this.allListData[index].question.reads[index1];
      list.makeQuestion = true; //小题是否做
      _this.$delete(
        _this.allListData[index].question.reads[index1].question.single_choices[
          index2
        ],
        "isSelect"
      ); //解决视图不跟新
      _this.$set(
        _this.allListData[index].question.reads[index1].question.single_choices[
          index2
        ],
        "isSelect",
        true
      );
      list.question.single_choices.forEach((v, inde) => {
        if (inde == index2) {
          v.isSelect = true;
        } else {
          v.isSelect = false;
        }
      });
    },
    clickShow(val) {
      //下一题上一题
      let _this = this;
      _this.quanpingshow = false;
      $("#Exam audio").each((i, v) => {
        v.pause();
      });
      $("#Exam video").each((i, v) => {
        v.pause();
      });
      _this.defaultIndex += val;
      _this.allListData.forEach((item, index) => {
        if (_this.defaultIndex == index) {
          _this.$delete(_this.allListData[index], "topicBool");
          _this.$set(_this.allListData[index], "topicBool", true);
        } else {
          _this.$delete(_this.allListData[index], "topicBool");
          _this.$set(_this.allListData[index], "topicBool", false);
        }
      });
      if (
        _this.defaultIndex > 0 &&
        _this.defaultIndex < _this.allListData.length - 1
      ) {
        _this.downBool = true; //激活下一题
        _this.upperBool = true; //激活上一题
      } else if (_this.defaultIndex == 0) {
        _this.downBool = true; //激活下一题
        _this.upperBool = false; //去掉上一题
      } else if (_this.defaultIndex == _this.allListData.length - 1) {
        _this.downBool = false; //去掉下一题
        _this.upperBool = true; //激活上一题
      }
    },
    clickCard(index) {
      // 点击答题卡
      let _this = this;
      _this.defaultIndex = index;
      _this.allListData.forEach((v) => {
        v.topicBool = false;
      });

      _this.allListData[index].topicBool = true;

      _this.$delete(_this.allListData[index], "topicBool");
      _this.$set(_this.allListData[index], "topicBool", true);
      if (
        _this.defaultIndex > 0 &&
        _this.defaultIndex < _this.allListData.length - 1
      ) {
        _this.downBool = true; //激活下一题
        _this.upperBool = true; //激活上一题
      } else if (_this.defaultIndex == 0) {
        _this.downBool = true; //激活下一题
        _this.upperBool = false; //去掉上一题
      } else if (_this.defaultIndex == _this.allListData.length - 1) {
        _this.downBool = false; //去掉下一题
        _this.upperBool = true; //激活上一题
      }
    },
    clickInput(e, i) {
      //多元激活样式
      let _this = this;
      let arr = [];
      $(`.blankStem${i} input`).each((index, item) => {
        arr.push($(item).val());
      });

      _this.allListData[i].input = arr;
      if (e.target.localName == "input") {
        if (e.target.value != "") {
          _this.$delete(_this.allListData[i], "makeQuestion");
          _this.$set(_this.allListData[i], "makeQuestion", true); //是否做题--答题卡
        }
      }
    },
    records() {
      // 创建试卷
      let _this = this;

      //获取所有数据
      _this.$api.shows(_this.question).then((res) => {
        _this.difficultys =
          res.data.question_class_record.question_class.difficultys;
        _this.gradeall = res.data.question_class_record.gradeall;
        _this.numbertest = res.data.question_class_record.items.length;
        _this.allObj = res.data.question_class_record; //所有数据
        _this.time = _this.allObj.question_class.durations * 60 * 1000; //倒计时
        _this.timeNumber = _this.allObj.question_class.durations * 60;
        // _this.time = 20 * 100;
        // _this.timeNumber = 2;
        _this.allListData = _this.allObj.items.filter(
          (item) => item.question_id_son == null
        ); //题型列表
        _this.redList = _this.allObj.items.filter(
          (item) => item.question_id_son != null
        ); //阅读小题

        // 按照question_id_son分类
        let b = _this.redList.reduce(
          (r, x) => (
            (r[x.question_id_son] || (r[x.question_id_son] = [])).push(x), r
          ),
          {}
        );
        let c = Object.keys(b).map((x) => b[x]);
        //吧单选中的阅读题替换阅读题
        c.forEach((v, i) => {
          v.forEach((item, index) => {
            _this.allListData.forEach((v1, i1) => {
              if (v1.question.type == 9) {
                if (_this.allListData[i1].question_id == item.question_id_son) {
                  v1.question.reads = v;
                }
              }
            });
          });
        });
        _this.allListData.forEach((item, index) => {
          item.topicBool = false; //是否显示题
          item.makeQuestion = false; //是否做题--答题卡
          // item.act=false;//当前位置
          item.activeImg = require("../../assets/image/11.png");
          item.pathImg = require("../../assets/image/10.png");
          item.sybelImg = require("../../assets/image/biaoji.png");
          item.sybelPath = require("../../assets/image/activebiaoji.png");
          // item.biaoji = 0;
          _this.$set(item, "biaoji", 0);
          _this.$set(item, "progress", 0);
          if (item.question.type == 1) {
            // 单选
            item.question.single_choices.forEach((item1, index1) => {
              item1.isSelect = false;
            });
          } else if (item.question.type == 2) {
            // 多选
            item.question.multiple_choices.forEach((item1, index1) => {
              item1.isSelect = false;
            });
          } else if (item.question.type == 3) {
            //判断题
            item.question.judges.forEach((item1) => {
              item1.isSelect = false;
            });
          } else if (item.question.type == 9) {
            //阅读题
            item.isShowJx = false;

            item.question.reads.forEach((item1) => {
              item1.makeQuestion = false; //是否做题
              if (item1.question) {
                item1.question.single_choices.forEach((item2) => {
                  item2.isSelect = false;
                });
              }
            });
          } else if (item.question.type == 6) {
            // 多元
            item.question.stem = item.question.stem.replace(
              /____/g,
              `<input  placeholder='点击答题'  />`
            );
            var n = item.question.stem.split("input").length - 1;
            item.input = [];
            for (let index = 0; index < n; index++) {
              item.input[index] = "";
            }
          } else if (item.question.type == 4) {
            //问答题text框
            item.message = "查看解析";
            item.massageScore = "自主判分";
            item.modelValue = "";
            _this.$set(item, "modelScore", "");
            item.btnBool = true; //判分按钮
            item.dialogVisible = false; //弹框
          } else if (item.question.type == 10) {
            //听力题text框
            item.modelValue = "";
            _this.$set(item, "modelScore", "");
            item.message = "查看解析";
            item.massageScore = "自主判分";
            item.dialogVisible = false; //弹框
            item.btnBool = true; //判分按钮
          }
        });
        _this.allListData[0].topicBool = true;
        this.loading.close();
      });
    },
    jiaojuan() {
      if (this.number == this.allListData.length) {
        this.DialogVisible = true;
        this.centerDialogVisible = false;
      } else {
        this.DialogVisible = false;
        this.centerDialogVisible = true;
      }
    },
    clicksubmit() {
      let _this = this;
      let list = [];
      _this.allListData.forEach((v) => {
        if (v.question.type == 1) {
          //单选
          if (v.makeQuestion) {
            //做了
            v.question.single_choices.forEach((v1, i1) => {
              switch (v.yes) {
                case 0:
                  v.yes = false;
                  break;

                case 1:
                  v.yes = true;
                  break;
              }
              if (v1.isSelect) {
                if (v1.yes == v1.isSelect) {
                  list.push({
                    id: v.id, //创建的试题id
                    question_class_id: v.question_class_id, //试题所属试卷id
                    question_class_record_id: v.question_class_record_id, //创建的试卷id
                    question_id: v.question_id,
                    answers: _this.listSum[i1],
                    state: 1,
                    grade: v.question.grade,
                  });
                } else {
                  list.push({
                    id: v.id, //创建的试题id
                    question_class_id: v.question_class_id, //试题所属试卷id
                    question_class_record_id: v.question_class_record_id, //创建的试卷id
                    question_id: v.question_id,
                    answers: _this.listSum[i1],
                    state: 0,
                    grade: 0,
                  });
                }
              }
            });
          } else {
            //没做
            list.push({
              id: v.id, //创建的试题id
              question_class_id: v.question_class_id, //试题所属试卷id
              question_class_record_id: v.question_class_record_id, //创建的试卷id
              question_id: v.question_id,
              answers: "",
              state: "",
              grade: 0,
            });
          }
        } else if (v.question.type == 2) {
          //多选
          if (v.makeQuestion) {
            //做了
            let youSel = ""; //你选中的
            let yesSel = ""; //答案;
            v.question.multiple_choices.forEach((v1, i1) => {
              switch (v.yes) {
                case 0:
                  v.yes = false;
                  break;
                case 1:
                  v.yes = true;
                  break;
              }
              if (v1.isSelect) {
                youSel += _this.listSum[i1]; //你选中的
              }
              if (v1.yes) {
                yesSel += _this.listSum[i1]; //答案
              }
            });
            if (youSel == yesSel) {
              list.push({
                id: v.id, //创建的试题id
                question_class_id: v.question_class_id, //试题所属试卷id
                question_class_record_id: v.question_class_record_id, //创建的试卷id
                question_id: v.question_id,
                answers: youSel,
                state: 1,
                grade: v.question.grade,
              });
            } else {
              list.push({
                id: v.id, //创建的试题id
                question_class_id: v.question_class_id, //试题所属试卷id
                question_class_record_id: v.question_class_record_id, //创建的试卷id
                question_id: v.question_id,
                answers: youSel,
                state: 0,
                grade: 0,
              });
            }
          } else {
            //没做
            list.push({
              id: v.id, //创建的试题id
              question_class_id: v.question_class_id, //试题所属试卷id
              question_class_record_id: v.question_class_record_id, //创建的试卷id
              question_id: v.question_id,
              answers: "",
              state: "",
              grade: 0,
            });
          }
        } else if (v.question.type == 3) {
          //判断题
          if (v.makeQuestion) {
            //做了
            v.question.judges.forEach((v1, i1) => {
              switch (v.yes) {
                case 0:
                  v.yes = false;
                  break;

                case 1:
                  v.yes = true;
                  break;
              }
              if (v1.isSelect) {
                if (v1.yes == v1.isSelect) {
                  list.push({
                    id: v.id, //创建的试题id
                    question_class_id: v.question_class_id, //试题所属试卷id
                    question_class_record_id: v.question_class_record_id, //创建的试卷id
                    question_id: v.question_id,
                    answers: v1.answer,
                    state: 1,
                    grade: v.question.grade,
                  });
                } else {
                  list.push({
                    id: v.id, //创建的试题id
                    question_class_id: v.question_class_id, //试题所属试卷id
                    question_class_record_id: v.question_class_record_id, //创建的试卷id
                    question_id: v.question_id,
                    answers: v1.answer,
                    state: 0,
                    grade: 0,
                  });
                }
              }
            });
          } else {
            //没做
            list.push({
              id: v.id, //创建的试题id
              question_class_id: v.question_class_id, //试题所属试卷id
              question_class_record_id: v.question_class_record_id, //创建的试卷id
              question_id: v.question_id,
              answers: "",
              state: "",
              grade: 0,
            });
          }
        } else if (v.question.type == 4) {
          //问答题
          if (v.makeQuestion) {
            //做了
            // if (v.question.answers[0].answer == v.modelValue) {
            list.push({
              id: v.id, //创建的试题id
              question_class_id: v.question_class_id, //试题所属试卷id
              question_class_record_id: v.question_class_record_id, //创建的试卷id
              question_id: v.question_id,
              answers: v.modelValue,
              state: 1,
              grade:
                v.modelScore == "" ? 0 : parseFloat(v.modelScore).toFixed(2),
            });

            // } else {
            //   list.push({
            //     id: v.id, //创建的试题id
            //     question_class_id: v.question_class_id, //试题所属试卷id
            //     question_class_record_id: v.question_class_record_id, //创建的试卷id
            //     question_id: v.question_id,
            //     answers: v.modelValue,
            //     state: 0,
            //     grade: 0,
            //   });
            // }
          } else {
            //没做
            list.push({
              id: v.id, //创建的试题id
              question_class_id: v.question_class_id, //试题所属试卷id
              question_class_record_id: v.question_class_record_id, //创建的试卷id
              question_id: v.question_id,
              answers: "",
              state: "",
              grade: 0,
            });
          }
        } else if (v.question.type == 6) {
          //多元填空题

          if (v.makeQuestion) {
            //做了
            // let strArr = [];
            let str = "";
            // $("#Exam #multipleList .stem p input").each((i1, v1) => {
            //   if ($(v1).data("id") == v.id) {
            //     strArr.push($(v1).val());
            //     str += $(v1).val() + "+";
            //   }
            // });

            let selStr = [];
            v.question.multiple_clozes.forEach((v1, i1) => {
              let arr = [];
              v1.answers.forEach((v2, i2) => {
                arr.push(v2.answer);
              });
              selStr.push(arr);
            });
            let grade = 0;
            let num = 0;
            v.input.forEach((item, index) => {
              str += item + "+";
              if (selStr[index].includes(item)) {
                grade += v.question.multiple_clozes[index].grade;
                num += 1;
              }
            });
            if (num == v.input.length) {
              list.push({
                id: v.id, //创建的试题id
                question_class_id: v.question_class_id, //试题所属试卷id
                question_class_record_id: v.question_class_record_id, //创建的试卷id
                question_id: v.question_id,
                answers: str,
                state: 1,
                grade: grade,
              });
            } else {
              list.push({
                id: v.id, //创建的试题id
                question_class_id: v.question_class_id, //试题所属试卷id
                question_class_record_id: v.question_class_record_id, //创建的试卷id
                question_id: v.question_id,
                answers: str,
                state: 0,
                grade: grade,
              });
            }
          } else {
            //没做
            list.push({
              id: v.id, //创建的试题id
              question_class_id: v.question_class_id, //试题所属试卷id
              question_class_record_id: v.question_class_record_id, //创建的试卷id
              question_id: v.question_id,
              answers: "",
              state: "",
              grade: 0,
            });
          }
        } else if (v.question.type == 9) {
          //阅读题
          v.question.reads.forEach((v1, i1) => {
            if (v1.makeQuestion) {
              //做了
              v1.question.single_choices.forEach((v3, i3) => {
                switch (v3.yes) {
                  case 0:
                    v3.yes = false;
                    break;

                  case 1:
                    v3.yes = true;
                    break;
                }
                if (v3.isSelect) {
                  if (v3.yes == v3.isSelect) {
                    list.push({
                      id: v1.id,
                      question_class_id: v.question_class_id,
                      question_class_record_id: v.question_class_record_id, //创建的试卷id
                      question_id: v1.question_id,
                      answers: _this.listSum[i3],
                      state: 1,
                      grade: v1.question.grade,
                    });
                  } else {
                    list.push({
                      id: v1.id,
                      question_class_id: v.question_class_id,
                      question_class_record_id: v.question_class_record_id, //创建的试卷id
                      question_id: v1.question_id,
                      answers: _this.listSum[i3],
                      state: 0,
                      grade: 0,
                    });
                  }
                }
              });
            } else {
              //没做
              list.push({
                id: v1.id,
                question_class_id: v.question_class_id,
                question_class_record_id: v.question_class_record_id, //创建的试卷id
                question_id: v1.question_id,
                answers: "",
                state: "",
                grade: 0,
              });
            }
          });
        } else if (v.question.type == 10) {
          //听力题
          if (v.makeQuestion) {
            //做了
            // if (v.question.dictations[0].answer == v.modelValue) {
            list.push({
              id: v.id, //创建的试题id
              question_class_id: v.question_class_id, //试题所属试卷id
              question_class_record_id: v.question_class_record_id, //创建的试卷id
              question_id: v.question_id,
              answers: v.modelValue,
              state: 1,
              grade: v.modelScore == "" ? 0 : v.modelScore,
            });
            // } else {
            //   list.push({
            //     id: v.id, //创建的试题id
            //     question_class_id: v.question_class_id, //试题所属试卷id
            //     question_class_record_id: v.question_class_record_id, //创建的试卷id
            //     question_id: v.question_id,
            //     answers: v.modelValue,
            //     state: 0,
            //     grade: 0,
            //   });
            // }
          } else {
            //没做
            list.push({
              id: v.id, //创建的试题id
              question_class_id: v.question_class_id, //试题所属试卷id
              question_class_record_id: v.question_class_record_id, //创建的试卷id
              question_id: v.question_id,
              answers: "",
              state: "",
              grade: 0,
            });
          }
        }
      });

      let error_total = 0;
      let grade = 0;

      list.forEach((v) => {
        if (v.state != 1) {
          error_total += 1;
        }
        grade += parseFloat(v.grade);
      });
      console.log(grade);
      let obj = {
        id: _this.question.id,
        param: {
          id: _this.question.id, //（创建的试卷id）
          question_class_id: _this.question.question_class_id, //（试卷id）
          user_id: _this.question.user_id, //（用户id）
          name: localStorage.name, //试卷名称 （试卷名称）
          total: list.length, //题目总数（计算一下）
          error_total: error_total, //错题数量（计算一下）
          durations:
            _this.allObj.question_class.durations -
            Math.floor(_this.timeNumber / 60),
          grade: grade,
          items: list,
        },
      };
      _this.$api.submittest(obj).then((res) => {
        _this.$message.success(res.data.message);
        _this.centerDialogVisible = false;
        setTimeout(() => {
          _this.$router.push("./Answersheet");
        }, 1000);
      });
    },
    //材料全屏
    showMax(index) {
      let _this = this;
      _this.quanpingshow = true;
      _this.gridData = _this.allListData[index].question.stem;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    let _this = this;

    var userAgent = navigator.userAgent; // 取得浏览器的userAgent字符串
    var isChrome = navigator.userAgent.indexOf("Chrome") > -1; // 是否是谷歌
    console.log("谷歌" + isChrome); // 返回 true  则是 ，false  则不是
    this.isChrome = isChrome;
    this.loading = this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    if (this.isChrome) {
      window.onresize = function () {
        if (!_this.fullScreenBool) {
          if (!_this.checkFull()) {
            // 退出全屏后要执行的动作
            _this.fullscreen = false;
            // _this.backGo();
            _this.fullScreenBoolNum = true;
            _this.fullNum += 1;
          }
        }
      };
    }

    _this.name = localStorage.name; //试卷名
    _this.titlename = localStorage.name;
    _this.question = _this.$store.state.question; //创建的试卷

    _this.records();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    if (this.isChrome) {
      // alt+tab    切屏
      if (this.fullNum < 3) {
        let code = 0;
        let code2 = 0;
        // document.onkeydown = function (e) {
        // };
        document.addEventListener(
          "keydown",
          function (e) {
            let evn = e || event;
            let key = evn.keyCode || evn.which || evn.charCode;
            if (key === 9) {
              code = 1;
            }
            if (key === 18) {
              code2 = 1;
            }
            if (code === 1 && code2 === 1) {
              // alert("Ctrl+Enter");
              //do something
              if (screenfull.isFullscreen) {
                screenfull.exit();
              }
              code = 0;
              code2 = 0;
            }
          },
          false
        );
        document.addEventListener(
          "keyup",
          function (e) {
            let evn = e || event;
            let key = evn.keyCode || evn.which || evn.charCode;
            if (key === 9) {
              code = 1;
            }
            if (key === 18) {
              code2 = 1;
            }
            if (code === 1 && code2 === 1) {
              // alert("Ctrl+Enter");
              //do something
              if (screenfull.isFullscreen) {
                screenfull.exit();
              }
              code = 0;
              code2 = 0;
            }
          },
          false
        );
      }
      // if (code1 == 9 && code2 == 18) {
      // }
    }
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {
    this.$nextTick(() => {
      $(".stemBox").on("click", ".thumbnail-i", function () {
        ImagePreview({
          images: [$(this).attr("src")],
          closeable: true,
        });
      });
    });
  }, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
@bgcolor: #fa8a89;
@redColor: #df3833;
#Exam {
  /deep/font {
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
      Segoe UI, Arial, Roboto, "PingFang SC", miui, "Hiragino Sans GB",
      "Microsoft Yahei", sans-serif;
  }
  /deep/.tijiaoDialog {
    .el-dialog {
      background: #fff;
      height: 350px;
      padding-top: 10px;

      //  padding-bottom: 110px;
      .el-dialog__header {
        height: 180px;
        background: url("../../assets/image/jiaojuan.png") no-repeat;
        background-size: 100%;
      }
      .el-dialog__body {
        text-align: center;
        padding: 20px 0;
        font-size: 18px;
      }
      .el-dialog__footer {
        .dialog-footer {
          .el-button {
            &:first-child {
              background: @bgcolor;
              color: #fff;
              border: @bgcolor;
            }
            &:nth-child(2) {
              background: #f5f5f5;
              color: #333;
              border: #ddd;
            }
          }
        }
      }
    }
  }
  /deep/.tijiaoDialog1 {
    .el-dialog__body {
      > div {
        display: flex;
        align-items: center;
        padding-left: 236px;
        font-size: 16px;
        .van-count-down {
          font-size: 16px;
          color: #606266;
          margin-top: 3px;
        }
      }
    }
  }
  /deep/.fullScreenBox {
    // 全屏
    .el-dialog {
      border-radius: 20px;
      .el-dialog__body {
      }
      button {
        background: @redColor;
        border-color: @redColor;
      }
    }
  }
  /deep/.fullScreenBox1 {
    .el-dialog {
      border-radius: 20px;
    }
    .el-dialog__body {
      text-align: center;
      > div {
        &:nth-child(2) {
          font-size: 20px;
          font-weight: 700;
          margin-top: 30px;
          color: #222;
          margin-bottom: 16px;
          span {
            color: #36aafd;
          }
        }
        &:nth-child(3) {
          font-size: 16px;
        }
      }
    }
    .el-dialog__footer {
      .dialog-footer {
        .el-button--primary {
          width: 250px;
          height: 50px;
          background-color: @redColor;
          border-radius: 10px;
          font-size: 16px;
          line-height: 50px;
          color: #fff;
          padding: 0;
          margin-top: 20px;
          border: @redColor;
        }
      }
    }
  }
  /deep/.fullScreenBox2 {
    .el-dialog {
      border-radius: 10px;
    }
    .el-dialog__body {
      text-align: center;
      > div {
        &:nth-child(2) {
          font-size: 20px;
          font-weight: 700;
          margin-top: 30px;
          color: #222;
        }
        &:nth-child(3) {
          color: #555;
          margin-top: 10px;
        }
      }
    }
    .el-dialog__footer {
      .el-button--primary {
        background: @redColor;
        border: none;
        width: 200px;
        border-radius: 100px;
        color: #fff;
        padding: 10px;
        text-shadow: none;
        font-size: 20px;
      }
    }
  }
  /deep/.fullScreenBox3 {
    .el-dialog__footer {
      .el-button--primary {
        background: #999;
      }
    }
  }
  /deep/.vdr.active:before {
    display: none;
  }
  /deep/.vdr-stick {
    display: none;
  }
  /deep/.question {
    width: 800px;
    // z-index: 0;
    z-index: 100 !important;
    margin-left: 285px;
    height: 1000px;

    // .el-dialog__title {
    //   background: @redColor;
    //   padding: 5px 10px;
    //   border-radius: 8px;
    //   color: #fff;
    //   font-size: 12px;
    //   cursor: pointer;
    // }
    .el-dialog {
      width: 800px;
      background: #fff;
      box-shadow: 0px 0px 10px #cfcfcf;
      padding: 10px;
      // margin: auto 24px;
      cursor: move;
      //  padding-bottom: 110px;
      // .el-dialog__header {
      //   height: 180px;
      //   background: url("../../assets/image/jiaojuan.png") no-repeat;
      //   background-size: 100%;
      // }
      .el-dialog__title {
        border: 1px solid #ccc;
        position: absolute;
        top: -11px;
        left: 328px;
        border-radius: 0px 0px 20px 20px;
        font-size: 14px;
        padding: 0px 15px;
        background: #eee;
        cursor: move;
      }
      .el-dialog__headerbtn {
        font-size: 28px;
      }
      .el-dialog__body {
        // text-align: center;
        color: #000;
        padding: 20px 10px;
        margin-top: 20px;
        font-size: 16px;
        line-height: 30px;
        height: 650px;
        // padding-top: 10px;
        overflow: auto;
        &::-webkit-scrollbar   {
          width: 5px;
          height: 0px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
          background: #999;
        }
        &::-webkit-scrollbar-track   {
          // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
          border-radius: 5px;
          background: #eee;
        }
      }
      // .el-dialog__footer {
      //   .dialog-footer {
      //     .el-button {
      //       &:first-child {
      //         background: @bgcolor;
      //         color: #fff;
      //         border: @bgcolor;
      //       }
      //       &:nth-child(2) {
      //         background: #f5f5f5;
      //         color: #333;
      //         border: #ddd;
      //       }
      //     }
      //   }
      // }
    }
  }
  width: 1350px;
  margin: 0 auto;
  height: 868px;
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  // 右边
  .Exam_left {
    width: 1026px;
    // height: 100%;
    /deep/.audioBox {
      // margin: 0 15px;
      margin-top: 10px;
      border-radius: 8px;
      position: relative;
      padding-top: 50px;
      box-shadow: 0px 0px 10px #cfcfcf;
      margin-bottom: 10px;
      .btnback {
        width: 100%;
        background: #fff;
        position: absolute;
        z-index: 50;
        display: flex;
        align-items: center;
        padding: 5px 25px !important;
        padding-bottom: 0 !important;
        // padding-left: 50px !important;
        box-sizing: border-box;
        top: 10px;
        left: 0px;
        img {
          width: 40px !important;
          height: 40px !important;
          cursor: pointer;
          margin: 0;
        }
        div {
          &:nth-child(2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 400px;
            margin: 0 10px;
          }
        }
      }
      audio {
        width: 94%;
        height: 55px;
        // outline: none;
        // display: flex;
        // background:#fff !important;
        // filter: invert(180,90,360); //c3 filter(滤镜) 属性
        // color: red;
        margin-left: 10px;
        border-radius: 10px;
      }

      audio::-webkit-media-controls-enclosure {
        background: rgba(255, 255, 255, 1); //白色底
        /* 滤镜反转为 rgba(0, 162, 255, 1);rgba(255, 93, 0, 0.8)  #7eb637   10,10,10  #757575 */
        border-radius: 0px;
      }
      .media-controls-container,
      .media-controls-container {
        background: #7eb637;
        //滤镜反转为 rgba(0, 162, 255, 1);rgba(255, 93, 0, 0.8)  #7eb637
        // border-radius: 10px;
        // color: red;
      }
      audio::-webkit-media-controls-play-button {
        height: 22px;
        width: 22px;
        min-width: 22px;
        // border-radius: 50%;
        flex-basis: 22px;
        // border-color: red;
        //  background: red;
        // background: #000;
        // color: #4280f4;
        // color: #25c17c;
      }
      // source{
      //   background: red;
      // }
      // 音量隐藏
      audio::-webkit-media-controls-volume-control-container {
        display: none !important;
        // color: red;
        // background: red;
      }

      audio::-webkit-media-controls-current-time-display {
        order: 1; //设置弹性盒对象元素的顺序
        color: #000;
        text-shadow: unset;
      }

      audio::-webkit-media-controls-time-remaining-display {
        order: 2;
        color: #000;
        text-shadow: unset;
      }
    }
    height: 815px;
    background: #fff;
    box-shadow: 0 0px 10px #cfcfcf;
    .Exam_left_main {
      width: 100%;
      background: #fff;
      // height: 720px;
      video {
        width: 400px;
        margin: 20px 0;
      }
      audio {
        margin: 20px 0px;
      }
      /deep/img {
        width: 200px;
        margin: 0 100px;
        cursor: pointer;
      }
      textarea {
        width: 100%;
        padding: 0 10px;
        line-height: 26px;
        color: #333;
        font-size: 16px;
        box-sizing: border-box;
        border: 1px solid #ddd;
        outline: none;
      }
      .allList {
        position: relative;
        padding: 0 21px;

        // .stemBox {
        //   height: 500px;
        //   overflow: auto;
        // }
        .allListLeftborder {
          width: 5px;
          height: 16px;
          display: inline-block;
          background: @redColor;
          position: absolute;
          left: -21px;
        }
        .connction {
          // display: none;
          cursor: pointer;
          > div {
            display: flex;
            align-items: center;
            font-size: 14px;
          }
          img {
            width: 14px;
            margin: 0 6px 0 20px !important;
            &:nth-child(3) {
              display: none;
            }
          }
          p {
            &:nth-child(4) {
              display: none;
            }
          }

          // background: url("../../assets/image/10.png") no-repeat -5px 10px;
        }

        .allListTitle {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;
          color: #333;
          padding: 17px 0;
          > div {
            &:nth-child(2) {
              display: flex;
              align-items: center;
              > div {
                &:nth-child(1) {
                  padding-top: 6px;
                }
                &:nth-child(2) {
                  margin: 0 10px;
                }
              }
            }
          }
        }
        .singleChoice,
        .multipleChoice,
        .judgesChoice,
        .blankChoice,
        .readingChoice,
        .questionsChoice,
        .listeningChoice {
          /deep/.fontweight {
            font-size: 16px;
            font-weight: bold;
          }
        }
        .singleChoice,
        .multipleChoice,
        .judgesChoice,
        .blankChoice {
          .singleScreen {
            // 单屏幕
            // background: #f9f9f9;
            height: 642px;
            width: 980px;
            padding: 0 10px;
            overflow: auto;
            &::-webkit-scrollbar {
              /*滚动条整体样式*/

              width: 5px; /*高宽分别对应横竖滚动条的尺寸*/

              height: 0px;
            }
            &::-webkit-scrollbar-thumb {
              /*滚动条里面小方块*/

              border-radius: 5px;

              -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

              background: #999;
            }
            &::-webkit-scrollbar-track {
              /*滚动条里面轨道*/

              -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

              border-radius: 5px;

              background: #eee;
            }
          }
        }
        .singleChoice {
          //单选题
          .singleStem {
            padding: 15px 0 11px;
          }
          .singleSelectList {
            padding: 5px 0 5px 10px;
            line-height: 24px;
            cursor: pointer;
            display: flex;
            // align-items: center;
            p {
              width: 900px;
            }
            div {
              width: 24px;
              height: 24px;
              line-height: 24px;
              font-size: 12px;
              background: #eee;
              text-align: center;
              margin-right: 10px;
              border-radius: 50%;
              &.isSelect {
                background: rgba(255, 56, 51, 0.3);
                color: #fff;
              }
            }
          }
        }
        .multipleChoice {
          //多选题
          .multipleStem {
            padding: 15px 0 11px;
          }
          .multipleSelectList {
            padding: 5px 0 5px 10px;
            line-height: 24px;
            cursor: pointer;
            display: flex;
            // align-items: center;
            p {
              width: 900px;
              // background: #000;
            }
            div {
              width: 24px;
              height: 24px;
              line-height: 24px;
              font-size: 12px;
              background: #eee;
              text-align: center;
              margin-right: 10px;
              border-radius: 50%;
              &.isSelect {
                background: rgba(255, 56, 51, 0.3);
                color: #fff;
              }
            }
          }
        }
        .judgesChoice {
          //判断题
          .judgesStem {
            padding: 15px 0 11px;
          }
          .judgesSelectList {
            padding: 5px 0 5px 10px;
            line-height: 24px;
            cursor: pointer;
            display: flex;
            align-items: center;
            div {
              width: 24px;
              height: 24px;
              line-height: 24px;
              font-size: 12px;
              background: #eee;
              text-align: center;
              margin-right: 10px;
              border-radius: 50%;
              &.isSelect {
                background: rgba(255, 56, 51, 0.3);
                color: #fff;
              }
            }
          }
        }
        /deep/ .blankChoice {
          //多元填空
          .blankStem {
            padding: 15px 0 11px;
            p {
              line-height: 25px;
            }

            /deep/input {
              color: #2d56b1;
              border: none;
              border-bottom: 1px solid #333;
              width: 180px;
              padding: 0 5px;
              text-align: center;
              font-size: 14px;
              // background: #f9f9f9;
            }
            // /deep/span {
            //   background-color: #f9f9f9 !important;
            // }
          }
        }
        .readingChoice {
          //阅读题
          position: relative;
          .card {
            position: absolute;
            left: 511px;
            display: flex;
            align-items: center;
            .allListLeftborder {
              top: 4px;
            }
          }
          .showMax {
            width: 90px;
            height: 35px;
            padding-left: 20px;
            font-size: 14px;
            background: url(../../assets/image/fangda.png) 5px 8px no-repeat
              #df3833;
            background-size: 18px;
            color: #fff;
            line-height: 35px;
            border-radius: 5px;
            text-align: center;
            position: absolute;
            top: 10px;
            left: 370px;
            cursor: pointer;
          }
          .singleScreen {
            // 单屏幕
            // background: #f9f9f9;
            height: 642px;
            width: 980px;
            .stemBox {
              display: flex;
              justify-content: space-between;

              .stem {
                width: 490px;
                padding: 0 10px;
                overflow: auto;
                height: 642px;
                border-right: 32px solid #fff;
                &::-webkit-scrollbar {
                  /*滚动条整体样式*/

                  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/

                  height: 0px;
                }
                &::-webkit-scrollbar-thumb {
                  /*滚动条里面小方块*/

                  border-radius: 5px;

                  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

                  background: #999;
                }
                &::-webkit-scrollbar-track {
                  /*滚动条里面轨道*/

                  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

                  border-radius: 5px;

                  background: #eee;
                }
              }
              .border {
                width: 1px;
                background: #dddddd;
              }
              .selectList {
                width: 490px;
                overflow: auto;
                height: 642px;
                border-left: 32px solid #fff;
                padding: 0 10px;
                .readTopic {
                  margin-top: 10px;
                  padding-bottom: 20px;
                  border-bottom: 1px solid #ccc;
                  &:last-child {
                    border: none;
                  }
                }
                &::-webkit-scrollbar {
                  /*滚动条整体样式*/

                  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/

                  height: 0px;
                }
                &::-webkit-scrollbar-thumb {
                  /*滚动条里面小方块*/

                  border-radius: 5px;

                  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

                  background: #999;
                }
                &::-webkit-scrollbar-track {
                  /*滚动条里面轨道*/

                  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

                  border-radius: 5px;

                  background: #eee;
                }
              }
            }
          }
          /deep/.readingStem {
            padding: 15px 0 11px;
            p {
              line-height: 30px;
            }
            div {
              line-height: 30px;
            }
          }
          .readingSelectList {
            padding: 5px 0 5px 10px;
            line-height: 24px;
            cursor: pointer;
            display: flex;
            // align-items: center;
            p {
              // background: #000;
              width: 380px;
            }
            div {
              width: 24px;
              height: 24px;
              line-height: 24px;
              font-size: 12px;
              text-align: center;
              background: #eee;
              text-align: center;
              margin-right: 10px;
              border-radius: 50%;

              &.isSelect {
                background: rgba(255, 56, 51, 0.3);
                color: #fff;
              }
            }
          }
        }
        .questionsChoice,
        .listeningChoice {
          .wenda {
            .jiexi {
              width: 100%;
              min-height: 160px;
              border: 1px solid #ccc;
              padding: 15px 32px;
              // overflow: auto;
              border-radius: 10px;
              // margin-bottom: 50px;
              &::-webkit-scrollbar   {
                width: 5px;
                height: 0px;
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                background: #999;
              }
              &::-webkit-scrollbar-track   {
                // -webkit-box-shadow: inset 0 0 5px rgba(0,  0,  0,  0.2);
                border-radius: 5px;
                background: #eee;
              }
            }
          }
          //问答题
          /deep/ .questionsStem {
            p {
              line-height: 30px;
            }
          }
          .btn {
            display: flex;
            justify-content: space-between;
          }
          .wenda {
            padding-top: 20px;
            .jiexi {
              div {
                line-height: 30px;
              }
              p {
                line-height: 30px;
              }
            }
          }
          position: relative;
          .card {
            position: absolute;
            left: 511px;
            display: flex;
            align-items: center;
            .allListLeftborder {
              top: 4px;
            }
          }
          textarea {
            width: 100%;
            height: 400px;
            resize: none;
            border-radius: 8px;
            padding: 20px;
          }
          .singleScreen {
            // 单屏幕
            // background: #f9f9f9;
            height: 642px;
            width: 980px;
            .stemBox {
              display: flex;
              justify-content: space-between;
              .stem {
                width: 490px;
                padding: 10px 10px;
                overflow: auto;
                height: 642px;
                border-right: 32px solid #fff;
                &::-webkit-scrollbar {
                  /*滚动条整体样式*/

                  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/

                  height: 0px;
                }
                &::-webkit-scrollbar-thumb {
                  /*滚动条里面小方块*/

                  border-radius: 5px;

                  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

                  background: #999;
                }
                &::-webkit-scrollbar-track {
                  /*滚动条里面轨道*/

                  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

                  border-radius: 5px;

                  background: #eee;
                }
              }
              .border {
                width: 1px;
                background: #dddddd;
              }
              .selectList {
                width: 490px;
                overflow: auto;
                height: 642px;
                border-left: 32px solid #fff;
                padding: 10px 10px;
                &::-webkit-scrollbar {
                  /*滚动条整体样式*/

                  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/

                  height: 0px;
                }
                &::-webkit-scrollbar-thumb {
                  /*滚动条里面小方块*/

                  border-radius: 5px;

                  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

                  background: #999;
                }
                &::-webkit-scrollbar-track {
                  /*滚动条里面轨道*/

                  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

                  border-radius: 5px;

                  background: #eee;
                }
              }
            }
          }
        }
        .listeningChoice {
          //听力题
          /deep/ .listeningStem {
            p {
              line-height: 25px;
            }
            line-height: 25px;
          }
          .btn {
            display: flex;
            justify-content: space-between;
          }
          .wenda {
            padding-top: 20px;
            .jiexi {
              div {
                line-height: 30px;
              }
              p {
                line-height: 30px;
              }
            }
          }
          position: relative;
          .card {
            position: absolute;
            left: 511px;
            display: flex;
            align-items: center;
            .allListLeftborder {
              top: 4px;
            }
          }
          textarea {
            width: 100%;
            height: 400px;
            resize: none;
            border-radius: 8px;
            padding: 20px;
          }

          .singleScreen {
            // 单屏幕
            // background: #f9f9f9;
            height: 642px;
            width: 980px;
            .stemBox {
              display: flex;
              justify-content: space-between;
              .stem {
                width: 490px;
                padding: 10px 10px;
                overflow: auto;
                height: 642px;
                border-right: 32px solid #fff;
                &::-webkit-scrollbar {
                  /*滚动条整体样式*/

                  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/

                  height: 0px;
                }
                &::-webkit-scrollbar-thumb {
                  /*滚动条里面小方块*/

                  border-radius: 5px;

                  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

                  background: #999;
                }
                &::-webkit-scrollbar-track {
                  /*滚动条里面轨道*/

                  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

                  border-radius: 5px;

                  background: #eee;
                }
              }
              .border {
                width: 1px;
                background: #dddddd;
              }
              .selectList {
                width: 490px;
                overflow: auto;
                height: 642px;
                border-left: 32px solid #fff;
                padding: 10px 10px;
                &::-webkit-scrollbar {
                  /*滚动条整体样式*/

                  width: 5px; /*高宽分别对应横竖滚动条的尺寸*/

                  height: 0px;
                }
                &::-webkit-scrollbar-thumb {
                  /*滚动条里面小方块*/

                  border-radius: 5px;

                  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

                  background: #999;
                }
                &::-webkit-scrollbar-track {
                  /*滚动条里面轨道*/

                  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

                  border-radius: 5px;

                  background: #eee;
                }
              }
            }
          }
        }
      }
    }
    .Exam_left_bottom {
      height: 94px;
      background: #fff;

      // justify-content: space-between;
      align-items: center;
      padding: 0 30px;
      .button {
        display: flex;
        width: 290px;
        margin: auto;
        line-height: 94px;
        div {
          &:nth-child(2) {
            button {
              background: #e03530;
              color: #fff;
              border-radius: 0px;
              border-color: #e03530;
            }
          }
          &:nth-child(1) {
            button {
              border-radius: 4px 0 0 4px;
              border-right: none;
              span {
                margin-right: 10px;
              }
            }
          }
          &:nth-child(3) {
            button {
              border-radius: 0px 4px 4px 0px;
              border-left: none;
              span {
                margin-left: 10px;
              }
            }
          }
        }
        .el-button {
          color: #717786;
          border: solid 1px #ccc;
          background: none;
        }
      }
      // div {
      //   // .el-button {
      //   //   background: @bgcolor;
      //   //   border: none;
      //   // }
      // }
    }
  }
  // 左边
  .Exam_right {
    height: 100%;
    width: 300px;
    .pauseBool {
      margin: auto;
      /deep/.el-dialog {
        border-radius: 4px;
      }
      /deep/.el-dialog__header {
        background: url(../../assets/image/zanting.826de4ff.png) no-repeat 0 0;
        height: 230px;
        background-size: 100% 100%;
      }
      .el-dialog__body {
        div {
          text-align: center;
          font-size: 18px;
          color: #333;
        }
      }
      button {
        height: 36px;
        width: 250px;
        background-color: @redColor;
        // background-color: @redColor;

        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        border: none;
      }
    }
    .Exam_left_top {
      width: 100%;
      background: #fff;
      text-align: center;
      // height: 50px;
      padding-top: 10px;
      border-radius: 8px;
      margin-bottom: 10px;
      box-shadow: 0 0px 10px #cfcfcf;
      > div {
        display: flex;
        padding: 10px 20px;
        justify-content: space-between;
        p {
          font-size: 14px;
          color: #999;
        }
        > div {
          display: flex;
          /deep/.el-rate {
            .el-rate__icon {
              margin-right: 0px;
            }
          }
        }
      }
      > p {
        font-size: 16px;
        font-weight: bold;
        color: #000;
      }
    }
    .Exam_right_top {
      padding: 17px 20px;
      margin-bottom: 10px;
      width: 100%;
      height: 200px;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 0px 10px #cfcfcf;
      > div {
        &:nth-child(1) {
          display: flex;
          align-items: center;
          padding: 0 80px;
          margin-bottom: 12px;
          img {
            margin-right: 13px;
          }
        }
        &:nth-child(2) {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
        }
        .num {
          line-height: 12px;
          font-size: 12px;
          color: #999;
        }
      }
      button {
        // margin: 0 20px 15px;
        color: #fff;
        font-size: 16px;
        border-radius: 6px;
        text-align: center;
        width: 260px;
        cursor: pointer;
        background: url(../../assets/image/papers.png) @redColor no-repeat 86px
          12px;
      }
      > div {
        &:nth-child(1) {
          text-align: center;
          line-height: 30px;
        }
      }
      .speed {
        width: 100%;
        height: 10px;
        border-radius: 5px;
        overflow: hidden;
        background-color: #f5f5f5;
        margin-right: 10px;
        //
        div {
          height: 100%;
          width: 0;
          background: @redColor;
        }
      }
      .time {
        padding: 10px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .countDown {
          /deep/ .van-count-down {
            font-size: 24px;
            color: #666;
            line-height: 40px;
            padding: 0 15px 0 12px;
            border-right: 1px solid #eee;
            margin-right: 8px;
          }
        }
        .timeDown {
          display: flex;
          > div {
            width: 30px;
            height: 30px;
            &.Pause {
              margin: 5px 12px;
              background: url(../../assets/image/013.png) no-repeat;
              background-size: 100% 100%;
              cursor: pointer;
            }
            &.zanting {
              margin: 5px 12px;
              background: url(../../assets/image/zanting.png) no-repeat 1px;
              background-size: 100% 70%;
              cursor: pointer;
            }
            &.caocun {
              margin: 5px 12px;
              background: url(../../assets/image/014.png) no-repeat;
              background-size: 100% 100%;
              cursor: pointer;
            }
          }
        }
      }
    }
    .Exam_right_main {
      box-shadow: 0 0px 10px #cfcfcf;
      background: #fff;
      height: 525px;
      border-radius: 8px;
      padding: 20px 0;
      .Exam_right_main_title {
        font-size: 16px;
        color: #000;
        font-weight: bold;
        line-height: 16px;
        padding: 0 20px;
        border-left: 5px solid @redColor;
      }
      > ul {
        padding: 20px;
        display: flex;
        // justify-content: space-between;
        li {
          margin: 0 10px 0 0;
          position: relative;
          i {
            width: 16px;
            height: 16px;
            margin-right: 6px;
            border-radius: 4px;
            display: inline-block;
            border: 1px solid #ccc;
            vertical-align: sub;
            box-sizing: border-box;
          }
          .index__yizuo {
            background: rgba(255, 56, 51, 0.3);
            border: none;
          }
          .index__weizuo {
            background: #fff;
          }
          .index__biaoji {
            border: 1px solid #ccc;
            background: #fff;
          }
          .index__biaojixi {
            background: url("../../assets/image/16.png") no-repeat 0 0;
            position: absolute;
            left: 0;
            top: 5px;
            border: none;
          }
        }

        // li {
        //   display: block;
        //   margin-right: 10px;
        //   font-size: 12px;
        //   // line-height: 16px;
        //   color: #666;
        //   width: 82px;
        //   height: 45px;
        //   box-sizing: border-box;
        //   border: 1px solid;
        //   padding: 5px;
        //   // padding-right: 0;
        //   border-radius: 2px;
        //   justify-content: space-around;
        //   justify-items: center;
        //   align-items: center;
        //   li{
        //     .index__yizuo{
        //       width: 20px;
        //       height: 20px;
        //       background: red;
        //     }
        //   }

        //   .text_done_right {
        //     flex: 1;
        //     p {
        //       font-size: 12px;
        //       color: #000;
        //       &:nth-child(2) {
        //         font-size: 12px;
        //         color: #a5a1a5;
        //       }
        //     }
        //   }

        //   // text-align: center;
        //   // line-height: 43px;
        //   display: flex;
        //   justify-content: space-around;
        //   i {
        //     // width: 16px;
        //     // height: 16px;
        //     margin-right: 0px;
        //     border-radius: 4px;
        //     display: inline-block;
        //     // border: 1px solid #ccc;
        //     vertical-align: sub;
        //     box-sizing: border-box;
        //     img {
        //       width: 19px;
        //       height: 18px;
        //       object-fit: cover;
        //     }
        //   }
        //   // &:nth-child(1) {
        //   //   border-color: #f19d81;
        //   //   background: #ffefe7;
        //   // }
        //   // &:nth-child(2) {
        //   //   border-color: #c8e9ff;
        //   //   background: #f0faff;
        //   // }
        //   // &:nth-child(3) {
        //   //   border-color: #dedede;
        //   //   background: #fff;
        //   // }
        // }
      }

      .index__BigtiNumberBox {
        padding: 0 14px;
        .stem {
          margin-bottom: 10px;
        }
        ul {
          max-height: 320px;
          overflow: auto;
          &::-webkit-scrollbar {
            /*滚动条整体样式*/

            width: 5px; /*高宽分别对应横竖滚动条的尺寸*/

            height: 0px;
          }
          &::-webkit-scrollbar-thumb {
            /*滚动条里面小方块*/

            border-radius: 5px;

            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

            background: #999;
          }
          &::-webkit-scrollbar-track {
            /*滚动条里面轨道*/

            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);

            border-radius: 5px;

            background: #eee;
          }
          display: flex;
          flex-wrap: wrap;
          padding: 0 8px;
        }
        li {
          display: inline-block;
          padding: 0 !important;
          font-size: 12px;
          position: relative;
          width: 23px;
          height: 23px;
          line-height: 23px !important;
          color: #333;
          text-align: center;
          border: 1px solid #ccc;
          background: #fff;
          margin: 0 15px 15px 0;
          border-radius: 4px;
          overflow: hidden;
          cursor: pointer;
          &.active {
            background-color: rgba(255, 56, 51, 0.3);
            color: #fff !important;
            border: none;
          }
          &.cur {
            background-image: url(../../assets/image/16.png);
            background-position: 0 0;
            background-repeat: no-repeat;
          }
          &.act {
            border-color: #df3833;
            color: #df3833;
          }
        }
      }
    }
  }
  .listeningAudio {
    .newAudio {
      padding: 0 !important;
      padding: 10px !important;
      /deep/.el-progress-bar__outer {
        height: 2px !important;
      }
      .bottm {
        display: flex;
        padding-top: 10px;
        align-items: center;
        .el-icon-video-play,
        .el-icon-video-pause {
          font-size: 30px;
          margin-right: 10px;
        }
        .bottomprogress {
          flex: 1;
          display: flex;
          align-items: center;
          .progress {
            flex: 1;
            background: #ebeef5;
            height: 3px;
            border-radius: 3px;
            margin: 0 10px;
            overflow: hidden;
            div {
              background: #409eff;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="less">
.is-fullscreen {
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999 !important;
}
</style>